import React from 'react';
import styles from './feedbackButton.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function FeedbackButton() {
  let width = useWindowWidth();

  return (
    <button className={styles.feedbackButton}>
      {width > 1024 ? 'Give us some feedback' : 'Feedback'}
    </button>
  );
}
